import tzlookup from "tz-lookup";
import { Base } from "./base";

class Tracking extends Base {
  constructor(data, units, overspeeds = [], truck_specific_need = true) {
    super();
    this.data = data;
    this.local_time = data?.local_time;
    this.position = {
        lat: data.matched_latitude,
        long: data.matched_longitude
    };
    this.truck_specific_need = truck_specific_need;
    this.time_object = new Date(data.local_time);
    this.time = data.time;
    this.related_overspeed_time = data.time;
    this.type = this._deemType(data, overspeeds);
    this.unit_name = units.find(u => u.unit_uuid === data.unit_uuid)?.unit_name;
    this.timezone = tzlookup(data.matched_latitude, data.matched_longitude)?.replace("_", " ");
  }

  static buildTrackings(data, units, overspeeds = [], truck_specific_need = true) {
    const trackings = [];
    const positions = data.filter((position) => Tracking.filterPoorGnss(position));
    positions.forEach((data) => {
      if (Tracking.isInUSACA(data.matched_latitude, data.matched_longitude)) {
        const tracking = new Tracking(data, units, overspeeds, truck_specific_need);
        trackings.push(tracking);
      }
    });
    return trackings;
  }

  static getZonesUUIDS(data) {
    return data.map((el) => el.notification_content?.zone_uuid).filter((el) => el);
  }

  _deemType(data, overspeeds) {
    let vehicleMovment = "stopped";
    if ((data.road_speed < 0 && data.gnss_speed > 4 && data.gnss_speed < 40) || data.road_speed > 0) {
      vehicleMovment = "running";
    }
    // If the value of the road speed from ecm and gnss speed from gnss is less than 0, then the vehicle is stopped.
    if (data.road_speed < 0 && data.gnss_speed <= 0) {
      vehicleMovment = "stopped";
    }
    // If there are both equal to 0, then the vehicle is idle.
    if (data.road_speed === 0 && data.gnss_speed === 0) {
      vehicleMovment = "idle";
    }
    // If there is a notification type 1 and the truck is driving, and road speed is higher than speed limit this is an overspeed,
    // Or if the tracking is during a notification of type overspeed and the road speed is higher than the speed limit, then it is an overspeed.
    if (this._isNotificationOverspeed() || this._isOverspeed(overspeeds)) {
      // Because now tracking can be overspeed we need to link the notification timestamp to the tracking so the search on overspeeds can be done.
      const overspeed = overspeeds.find(overspeed => this.time_object  >= new Date(overspeed.start_datetime.getTime() - 20 * 1000) && this.time_object <= new Date(overspeed.end_datetime.getTime() + 20 * 1000));
      this.related_overspeed_time = overspeed?.time;
      return "overspeed";
    }
    if (data.notification_type_code === 4) {
      return "bridge";
    }
    if (data.road_name.includes("E-SMART School Zone")) {
      return "school";
    }
    if (data.road_name.includes("E-SMART Truck Specific") && this.truck_specific_need) {
      return "truck-specific";
    }
    return vehicleMovment;
  }

  _isOverspeed(overspeeds) {
    const isDuringOverspeed = overspeeds.some(overspeed => this.time_object >= new Date(overspeed.start_datetime.getTime() - 20 * 1000) && this.time_object <= new Date(overspeed.end_datetime.getTime() + 20 * 1000));
    return (this.data.road_speed > (this.data.esmart_target_kmh !== 0 ? this.data.esmart_target_kmh : this.data.esmart_algo_speed_limit)) && isDuringOverspeed;
  }

  _isNotificationOverspeed() {
    const typeOverSpeed = this.data.notification_type_code === 1;
    const isMoving = this.data.road_speed > 5;
    const isNotOutlier = !this.data?.notification_content?.is_outlier;
    const isSpeedHigerThanLimit = this.data.road_speed > (this.data.esmart_target_kmh !== 0 ? this.data.esmart_target_kmh : this.data.esmart_algo_speed_limit);
    return typeOverSpeed && isMoving && isNotOutlier && isSpeedHigerThanLimit;
  }

  static filterPoorGnss(data) {
    return data.dilution_of_precision < 20 && !data.esmart_algo_speed_limit == 0 && !data.esmart_algo_speed_limit == 0;
  }
}

export { Tracking };