import { sub } from "../utils/dates";

class Overspeed {
  constructor(data) {
    this.duration = data?.notification_content?.duration || data?.duration_in_s;
    this.speed_limit_applied = data?.notification_content?.speed_limit_applied || data?.esmart_algo_speed_limit;
    this.speed_limit_at_peak = data?.notification_content?.speed_limit_at_peak || data?.speed_limit_in_kmh;
    this.speed_delta_at_peak = data?.notification_content?.speed_delta_at_peak || data?.speed_delta_in_kmh;
    this.start_timestamp = data?.notification_content?.start_timestamp;
    this.time = data.time;
    this.end_datetime = new Date(data.local_time);
    this.start_datetime = sub(new Date(data.local_time), data?.notification_content?.duration || data?.duration_in_s, "s");
  }

  static filterTrackingOverspeeds(datas) {
    const overspeeds = []
    const raw_overspeed = datas.filter(data => data.notification_type_code === 1 && data.road_speed > 5 && !data?.notification_content?.is_outlier);
    raw_overspeed.forEach(overspeed => overspeeds.push(new Overspeed(overspeed)));
    return overspeeds;
  }
}

export { Overspeed };