import { get, post } from "../axios.js";

const zoneListing = async (data) => {

    const pool_id = import.meta.env.VITE_MFA_POOL_ID;

    const baseURL = import.meta.env.VITE_ENVIRONMENT === 'pre-production' ? 'https://external.staging.esmartcontrol.net/' : import.meta.env.VITE_API_BASE_URL;

    const url = `${baseURL}zone/zone`;

    data.pool_id = pool_id;

    try {
        const response = await get({ data, url });
        if (response.data) {
            return response.data;
        } else {
                throw new Error("There is no data available.");
        }
    } catch (err) {
        throw err;
    }
}

const zoneListingPost = async (data) => {

    const pool_id = import.meta.env.VITE_MFA_POOL_ID;

    const baseURL = import.meta.env.VITE_ENVIRONMENT === 'pre-production' ? 'https://external.staging.esmartcontrol.net/' : import.meta.env.VITE_API_BASE_URL;

    const url = `${baseURL}zone/zone/list`;

    data.pool_id = pool_id;

    try {
        const response = await post({ data, url });
        if (response.data) {
            return response.data;
        } else {
                throw new Error("There is no data available.");
        }
    } catch (err) {
        throw err;
    }
}

export { zoneListingPost };

export default zoneListing;
