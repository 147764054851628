<template>
  <GMapMap :center="center" :zoom="zoom" :style="mapStyle" :options="options" :max-zoom="17" :min-zoom="5" >
    <GMapPolygon
      v-if="zones.length > 0 && props.isZonesShown"
      v-for="(zone, index) in zonesMaker"
      :key="index"
      :paths="zone.paths"
      :options="zone.options"
      @click="openZoneInfoWindow(zone)"
    />
    <GMapMarker 
      :key="index"
      v-if="props.trackings.length > 0"
      v-for="(tracking, index) in props.trackings"
      :position="{lat: tracking.position.lat, lng: tracking.position.long}"
      :clickable="true"
      :draggable="false"
      @click="openInfoWindow(tracking)"
      :icon="{
        url: properSVG(interceptIconType(tracking.type)),
        scaledSize: {height: 20, width: 15}
      }"
    />
    <GMapInfoWindow
      v-if="activeTracking"
      :position="{lat: activeTracking?.position?.lat, lng: activeTracking?.position?.long}"
    >
      <popin-tracking :data="activeTracking" @close="closePopin()" />
    </GMapInfoWindow>
    <GMapInfoWindow
      v-if="activeZone"
      :position="{lat: activeZone?.area?.coordinates[0][0][1], lng: activeZone?.area?.coordinates[0][0][0]}"
    >
      <popin-zone :data="activeZone" @close="closePopinZone()" />
    </GMapInfoWindow>
  </GMapMap>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import zoneListing from "../../../api/zones/list"; 
import { properSVG } from "../../../utils/svg";
import { options } from "../../../constants/map.control";
  
const props = defineProps( {
  trackings: {
    type: Array,
    default: [],
  },
  center: Object,
  zoom: Number,
  zonesUUIDS: {
    type: Array,
    default: [],
  },
  isZonesShown: Boolean,
  isTruckSpecifShown: Boolean,
});
  
const activeTracking = ref(null);
const activeZone = ref(null);
const isZonesShown = computed(() => props.isZonesShown);
const isTruckSpecifShown = computed(() => props.isTruckSpecifShown);

const zonesMaker = ref([]);
const zones = ref([]);  

const mapStyle = ref({
  height: '83vh',
  width: '100%',
});

const fetchingZones = async () => {
  if (props.zonesUUIDS?.length === 0) {
    return;
  }
  const params = {uuids: props.zonesUUIDS.join(",")};
  const { data } = await zoneListing(params);
  const polyZones = [];
  data.filter((zone) => zone?.category_name !== "Corrections").forEach((zone) => {
    polyZones.push({
      paths: zone?.area?.coordinates[0].map((coords) => ({lat: coords[1], lng: coords[0]})),
      options: {
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        indexID: zone?.uuid,
      }
    })
  });
  zones.value = data;
  zonesMaker.value = polyZones
}

watch(isZonesShown, async (shown) => {
  if (props.zoneUUIDS?.length === 0 && !shown) {
    return;
  }
  await fetchingZones();
});

const closePopin = () => {
  activeTracking.value = null;
};

const closePopinZone = () => {
  activeZone.value = null;
};

const openInfoWindow = (tracking) => {
  activeTracking.value = tracking;
};

const openZoneInfoWindow = (zone) => {
  const selectedZone = zones.value.find((z) => z.uuid === zone.indexID);
  activeZone.value = selectedZone;
};
// Intercept icon type to display the running icon if the truck specific is not shown
const interceptIconType = (type) => {
  if (type === 'truck-specific' && !isTruckSpecifShown.value) {
    return "running";
  }
  return type;
}

</script>